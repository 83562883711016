import DolorDiarreaEstomago from '../../../assets/images/blog/DolorDiarreaEstomago.webp';
import EfectosSecundarios from '../../../assets/images/blog/EfectosSecundarios.webp';
import GirlEnferma from '../../../assets/images/blog/GirlEnferma.webp';

const data = [
	{
		id: 1,
		image: GirlEnferma,
		title: 'Guía para padres: Manejo de la diarrea en niños de forma segura.',
		category: 'Salud',
		description: 'Cómo manejar la diarrea en niños de forma segura.',
		content: `
		  <div>
			<p>Para ustedes como padres, la diarrea en sus hijos, especialmente en los más pequeños, es un tema preocupante. Sin embargo, en la mayoría de los casos, la diarrea puede ser causada por infecciones virales o bacterianas leves y se resuelve en un par de días.</p>
			<p>Te presentamos estos consejos para que no te alarmes más y que puedas manejar la diarrea de tus pequeños de forma segura:</p>
			<h3>Dieta blanda:</h3>
			<p>Incluye en la alimentación de tu hijo alimentos suaves y fáciles de digerir para que ayuden a reducir la irritación intestinal y disminuir la frecuencia de evacuaciones. Estos alimentos pueden ser arroz blando, avena, puré de zanahoria, compota de manzana, pollo, pescado blanco, entre otros. Evita la carne roja, embutidos, quesos grasos o la comida rápida. Ten en cuenta que puede ser que tu hijo pierda el apetito; en estos casos, no recomendamos forzarlos a comer. Asegura una buena hidratación.</p>
			<h3>Hidratación:</h3>
			<p>Es fundamental que tu hijo/hija esté bien hidratado durante la diarrea ya que, con las evacuaciones constantes, los pequeños pierden muchos líquidos. Ofrece líquidos como agua, caldos o suero oral para prevenir la deshidratación. Evita darle jugos de frutas concentrados ya que pueden empeorar la diarrea.</p>
			<h3>Consulta a un médico:</h3>
			<p>Si la diarrea persiste por más días, si no quieren recibir alimento y hay signos de deshidratación como la boca seca, ausencia de lágrimas al llorar, o tu hijo(a) empieza a presentar fiebres altas o sangre en las heces, es importante que visites al médico de inmediato.</p>
			<h3>Evita uso de medicamentos sin consultar al médico:</h3>
			<p>Evita darle a los niños antidiarreicos sin consultar a tu médico, especialmente cuando tienen menos de 6 años.</p>
			<h3>Higiene:</h3>
			<p>Siempre es importante mantener una buena higiene en casa y lavar tus manos con frecuencia para prevenir la propagación de infecciones.</p>
			<p>La gran mayoría de casos de diarrea se resuelven por sí solos con cuidados simples en casa.</p>
		  </div>
		`,
		ReadingTime: '2 min read',
	  },
	  {
		id: 2,
image: DolorDiarreaEstomago,
title: 'Perenterol, tu aliado contra la diarrea infecciosa aguda.',
category: 'Salud',
description: 'Información sobre cómo Perenterol puede ayudar contra la diarrea infecciosa aguda.',
content: `
  <div>
	<h2>Perenterol, tu aliado contra la diarrea infecciosa aguda</h2>
	<p>La diarrea es un problema frecuente que puede afectar a cualquier persona. Es la expulsión de heces acuosas y blandas. Se puede considerar que una persona tiene diarrea cuando evacúa tres o más veces en un día para luego desaparecer. La diarrea infecciosa aguda es un problema de salud que suele ser común en aquellas áreas donde las condiciones sanitarias son deficientes.</p>
	
	<h3>Las causas más frecuentes de una diarrea infecciosa son:</h3>
	<ul>
	  <li>Bacterias.</li>
	  <li>Virus.</li>
	  <li>Parásitos.</li>
	</ul>
	
	<h3>Las personas con diarrea infecciosa pueden tener uno o más de los siguientes síntomas:</h3>
	<ul>
	  <li>Sangre en las heces</li>
	  <li>Fiebre y escalofrío</li>
	  <li>Mareo y vértigo</li>
	  <li>Vómito</li>
	</ul>
	
	<h3>¿Cómo funciona Perenterol ante la diarrea infecciosa aguda?</h3>
	<p>Estudios demuestran que la administración de <strong>Saccharomyces Boulardii</strong> puede reducir la duración de la diarrea, disminuir la frecuencia de las evacuaciones y ayudar a prevenir la recurrencia de la diarrea. A diferencia de muchos medicamentos que son bacterias probióticas, Perenterol es una levadura que ofrece una serie de ventajas únicas en el tratamiento de la diarrea infecciosa aguda:</p>
	
	<h4>Restaura el equilibrio de la flora intestinal:</h4>
	<p>La diarrea infecciosa aguda puede causar un desequilibrio en la flora intestinal, lo que hace que los síntomas empeoren y se prolonguen. Perenterol ayuda a restaurar el equilibrio y aumenta la cantidad de bacterias benéficas en el intestino.</p>
	
	<h4>Detiene el crecimiento de microorganismos malignos:</h4>
	<p><strong>Saccharomyces Boulardii</strong>, el activo presente en Perenterol, produce sustancias antimicrobianas que impiden el crecimiento de bacterias dañinas para el intestino, lo que ayuda a combatir la infección y a reducir la gravedad de la diarrea.</p>
	
	<h4>Refuerza el sistema inmunológico:</h4>
	<p>Perenterol fortalece las defensas del cuerpo contra las infecciones intestinales y acelera el proceso de recuperación.</p>
	
	<p>La dosis recomendada de Perenterol es de 2 cápsulas diarias en adultos y mayores de 12 años cada 24 horas y en niños un sobre cada 24 horas. Siempre es recomendable consultar a su médico de confianza antes de consumirlo.</p>
	
	<p>Perenterol ofrece restaurar el equilibrio intestinal. Si está presentando síntomas de Diarrea Infecciosa Aguda, no dude en consultar con su médico para implementar Perenterol en su tratamiento.</p>
  </div>
`,
ReadingTime: '2 min read',
	  },
	  {
		id: 3,
		image: EfectosSecundarios,
		title: '¿Cómo afrontar los efectos secundarios de los antibióticos?',
		category: 'Salud',
		description: 'Información sobre cómo afrontar los efectos secundarios de los antibióticos y cómo Perenterol puede ayudar.',
		content: `
		  <div>
			<h2>¿Cómo afrontar los efectos secundarios de los antibióticos?</h2>
			<p>Los antibióticos son medicamentos que se utilizan para combatir las infecciones bacterianas, pero en muchas ocasiones, su uso puede tener efectos secundarios ya que pueden afectar a millones de bacterias buenas que viven en el tracto intestinal. Uno de esos efectos es la diarrea. Los antibióticos se encargan de eliminar las bacterias dañinas, pero en el cumplimento de su trabajo, también exterminan algunas bacterias buenas presentes en el intestino, que son bacterias encargadas de protegerlo. Esto da lugar al crecimiento descontrolado de otras bacterias que alteran el equilibrio de la flora intestinal.</p>
			
			<p>La diarrea asociada al uso de antibióticos (DAA) es la que surge como resultado de este desequilibrio (disbiosis), ya que se altera la composición y funciones normales de nuestra flora intestinal. Se sabe que hasta el 35% de los pacientes bajo algún tratamiento con antibióticos pueden desarrollar esta condición.</p>
			
			<p>Pero ¿en qué se diferencia la diarrea normal de la diarrea asociada al uso de antibióticos? Es una disminución de la consistencia de las heces, acompañada de un incremento en el número de evacuaciones, durante o después de un tratamiento de antibióticos.</p>
			
			<p>El malestar puede estar presente al inicio del tratamiento con antibióticos, pero también puede presentarse hasta 8 semanas después de haber concluido el tratamiento. Afortunadamente, Perenterol es una de las formas para mitigar la Diarrea Asociada a Antibióticos.</p>
			
			<h3>¿Cómo ayuda Perenterol a la DAA?</h3>
			<p>La levadura probiótica única, <strong>Saccharomyces Boulardii</strong>, presente en Perenterol, ha demostrado que es efectiva en el tratamiento de dicho mal, ya que tiene propiedades beneficiosas que pueden ayudar a restaurar el equilibrio intestinal y aliviar todos los síntomas que vengan con la diarrea sin restarle poder a los antibióticos y sin ser afectada por estos.</p>
			
			<h3>¿Cómo actuar ante estos casos de DAA?</h3>
			<p>Además de cumplir con todas las recomendaciones de su médico de confianza recomendamos:</p>
			<ul>
						<li>Tomar abundantes líquidos</li>
						<li>Consumir dieta blanda</li>
						<li>Consultar con su médico sobre el uso de probióticos como Perenterol para restaurar el equilibrio de la Microbiota Intestinal</li>
			</ul>
		  </div>
		`,
		ReadingTime: '2 min read',
		},
  ];
  
  export default data;
  